import "../../css/App.css";
import HeaderOfSegmentTable from "./HeaderOfSegmentTable";
import { getSegmentList, updateSegment } from "../../fsrc/fsrc";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Segmentation = (props) => {
  const [segmentName, setSegmentName] = useState(undefined);
  const [segmentArray, setSegmentArray] = useState(false);

  /*
  {
    segment_name: 'Светильники',
    max_rrc: 41,
    income_norm: 1,
    rrc_max_diff: 41,
    segment_row: 'Светильники_2',
    min_price: 14,
    max_price: 31,
    proc_vikypa: 14
  }
    */

  const handleInputChange = (event, id, field) => {
    const { value } = event.target;
    setSegmentArray((prevElements) => {
      let newData = prevElements.data.map((el) => {
        return el.segment_row === id ? { ...el, [field]: Number(value) } : el;
      });
      let newArray = { data: newData };
      return newArray;
    });
  };

  function onInputSegmentValueChange(event) {
    setSegmentName(event.target.value);
  }

  function updateSegmentButton() {
    if (segmentName === "" || segmentName === undefined) {
      alert("Введите сегмент!");
    } else {
      updateSegment(segmentArray.data);
      console.log(segmentArray.data);
    }
  }

  async function getSegmentInfo() {
    console.log(segmentName);
    await getSegmentList(segmentName).then(async (array) => {
      await setSegmentArray(array);
    });
    console.log(segmentArray);
  }

  return (
    <div className="segmentMainDive">
      <div className="segmentNextDivAfterMainLeft">
        <HeaderOfSegmentTable theText="Название сегмента" />
        <input
          className="segment-input-in-segment"
          type="text"
          id={"00"}
          title={"Название сегмента"}
          onInput={onInputSegmentValueChange}
        />
        <div className="segmentbtn-new-container">
          <button
            className="segmentbtn-new"
            type="button"
            onClick={getSegmentInfo}
          >
            Загрузить сегмент
          </button>
          <button
            className="segmentbtn-new"
            type="button"
            onClick={updateSegmentButton}
          >
            Сохранить
          </button>
          <Link to="/segmentation" className="segmentbtn-new">
            <div>Добавление сегмента</div>
          </Link>
        </div>
      </div>
      <div className="segmentNextDivAfterMainRight">
        <div className="segmetOneRow">
          <HeaderOfSegmentTable theText="Максимальный РРЦ для нормы прибыли" />
          <HeaderOfSegmentTable theText="Норма прибыли для данной РРЦ" />
          <HeaderOfSegmentTable theText="Показатель неценки к РРЦ, не более" />
          <HeaderOfSegmentTable theText="Мин. цена" />
          <HeaderOfSegmentTable theText="Макс. цена" />
          <HeaderOfSegmentTable theText="Процент возврата" />
        </div>

        {segmentArray
          ? segmentArray.data.map((el) => {
              return (
                <div className="segmetOneRow">
                  <input
                    className="input-in-segment"
                    type="number"
                    id={el.segment_row}
                    value={el.max_rrc}
                    onInput={(e) => {
                      handleInputChange(e, el.segment_row, "max_rrc");
                    }}
                  />
                  <input
                    className="input-in-segment"
                    type="number"
                    id={el.segment_row}
                    value={el.income_norm}
                    onInput={(e) => {
                      handleInputChange(e, el.segment_row, "income_norm");
                    }}
                  />
                  <input
                    className="input-in-segment"
                    type="number"
                    id={el.segment_row}
                    value={el.rrc_max_diff}
                    onInput={(e) => {
                      handleInputChange(e, el.segment_row, "rrc_max_diff");
                    }}
                  />
                  <input
                    className="input-in-segment"
                    type="number"
                    id={el.segment_row}
                    value={el.min_price}
                    onInput={(e) => {
                      handleInputChange(e, el.segment_row, "min_price");
                    }}
                  />
                  <input
                    className="input-in-segment"
                    type="number"
                    id={el.segment_row}
                    value={el.max_price}
                    onInput={(e) => {
                      handleInputChange(e, el.segment_row, "max_price");
                    }}
                  />
                  <input
                    className="input-in-segment"
                    type="number"
                    id={el.segment_row}
                    value={el.proc_vikypa}
                    onInput={(e) => {
                      handleInputChange(e, el.segment_row, "proc_vikypa");
                    }}
                  />
                </div>
              );
            })
          : () => {
              return <div>Loading...</div>;
            }}
      </div>
    </div>
  );
};

export default Segmentation;
