import "../../css/App.css";
import HeaderOfSegmentTable from "./HeaderOfSegmentTable";
import SegmentDBVisual from "./SegmentDBVisual";
import { putSegmentInfoToDataBase } from "../../fsrc/fsrc";
import { useState } from "react";
import { Link } from "react-router-dom";

const Segmentation = (props) => {
  const [newLines, setNewLines] = useState([
    [
      { id: "0", title: "Максимальный РРЦ для нормы прибыли", value: "" },
      { id: "1", title: "Норма прибыли для данной РРЦ", value: "" },
      { id: "2", title: "Показатель неценки к РРЦ, не более", value: "" },
      { id: "3", title: "Мин. цена", value: "" },
      { id: "4", title: "Макс. цена", value: "" },
      { id: "5", title: "Процент возврата", value: "" },
    ],
  ]);
  const [segmentName, setSegmentName] = useState(undefined);

  function removeNewLine() {
    let buffer = [];
    for (let i = 0; i < newLines.length; i++) {
      buffer.push(newLines[i]);
    }

    buffer.pop();
    setNewLines(buffer);
  }

  function addNewLine() {
    let buffer = [];
    let lastLD = 0;
    for (let i = 0; i < newLines.length; i++) {
      buffer.push(newLines[i]);
      if (i + 1 === newLines.length) {
        lastLD = newLines[i][5].id;
      }
    }

    buffer.push([
      {
        id: String(Number(lastLD) + 1),
        title: "Максимальный РРЦ для нормы прибыли",
        value: "",
      },
      {
        id: String(Number(lastLD) + 2),
        title: "Норма прибыли для данной РРЦ",
        value: "",
      },
      {
        id: String(Number(lastLD) + 3),
        title: "Показатель неценки к РРЦ, не более",
        value: "",
      },
      {
        id: String(Number(lastLD) + 4),
        title: "Мин. цена",
        value: "",
      },
      {
        id: String(Number(lastLD) + 5),
        title: "Макс. цена",
        value: "",
      },
      {
        id: String(Number(lastLD) + 6),
        title: "Процент возврата",
        value: "",
      },
    ]);
    setNewLines(buffer);
  }

  function onInputValueChange(event) {
    console.log(event.target);
    for (let i = 0; i < newLines.length; i++) {
      newLines[i].forEach((el) => {
        if (el.id === event.target.id) {
          el.value = event.target.value;
        }
      });
    }
  }

  function onInputSegmentValueChange(event) {
    setSegmentName(event.target.value);
  }

  function dataPushToServer() {
    if (segmentName === "" || segmentName === undefined) {
      alert("Введите сегмент!");
    } else {
      let arrayToPush = newLines.slice();

      for (let i = 0; i < arrayToPush.length; i++) {
        arrayToPush[i].forEach((el) => {
          el.segmentRowId = segmentName + "_" + i;
          el.value = Number(el.value);
          el.id = Number(el.id);
        });
      }

      let allInfo = {
        segmentName: segmentName,
        data: arrayToPush,
      };

      putSegmentInfoToDataBase(allInfo);
      console.log(allInfo);
    }
  }

  return (
    <div className="segmentMainDive">
      <div className="segmentNextDivAfterMainLeft">
        <HeaderOfSegmentTable theText="Название сегмента" />
        <input
          className="segment-input-in-segment"
          type="text"
          id={"00"}
          title={"Название сегмента"}
          onInput={onInputSegmentValueChange}
        />
        <div className="segmentbtn-new-container">
          <button className="segmentbtn-new" type="button" onClick={addNewLine}>
            +
          </button>
          <button
            className="segmentbtn-new"
            type="button"
            onClick={removeNewLine}
          >
            -
          </button>
          <button
            className="segmentbtn-new"
            type="button"
            onClick={dataPushToServer}
          >
            Сохранить
          </button>
          <Link to="/segmentation/show" className="segmentbtn-new">
            <div>Посмотреть данные</div>
          </Link>
          <Link to="/segmentation/edit" className="segmentbtn-new">
            <div>Обновить сегмент</div>
          </Link>
        </div>
      </div>
      <div className="segmentNextDivAfterMainRight">
        <div className="segmetOneRow">
          <HeaderOfSegmentTable theText="Максимальный РРЦ для нормы прибыли" />
          <HeaderOfSegmentTable theText="Норма прибыли для данной РРЦ" />
          <HeaderOfSegmentTable theText="Показатель неценки к РРЦ, не более" />
          <HeaderOfSegmentTable theText="Мин. цена" />
          <HeaderOfSegmentTable theText="Макс. цена" />
          <HeaderOfSegmentTable theText="Процент возврата" />
        </div>

        {newLines.map((el) => {
          return (
            <div className="segmetOneRow">
              <input
                className="input-in-segment"
                type="number"
                id={el[0].id}
                title={el[0].title}
                onInput={onInputValueChange}
              />
              <input
                className="input-in-segment"
                type="number"
                id={el[1].id}
                title={el[1].title}
                onInput={onInputValueChange}
              />
              <input
                className="input-in-segment"
                type="number"
                id={el[2].id}
                title={el[2].title}
                onInput={onInputValueChange}
              />
              <input
                className="input-in-segment"
                type="number"
                id={el[3].id}
                title={el[3].title}
                onInput={onInputValueChange}
              />
              <input
                className="input-in-segment"
                type="number"
                id={el[4].id}
                title={el[4].title}
                onInput={onInputValueChange}
              />
              <input
                className="input-in-segment"
                type="number"
                id={el[5].id}
                title={el[5].title}
                onInput={onInputValueChange}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Segmentation;
