const hostName = "";

async function putDataToDataBase(data) {
  const url = `${hostName}/api/v1/vamsvet/putDataToDataBase`;
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };

  await fetch(url, options)
    .then((response) => {
      response.json().then((jsonResponse) => {
        console.log(jsonResponse);
        return jsonResponse;
      });
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
}

async function getSellerPropertiesFromDataBase() {
  const url = `${hostName}/api/v1/vamsvet/getSellerPropertiesFromDataBase`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  let data = [{ id: 1, value: "No data", title: "No data" }];
  await fetch(url, options)
    .then(async (response) => {
      await response
        .json()
        .then((jsonResponse) => {
          data = jsonResponse.data;
        })
        .catch((e) => {
          return;
        });
    })
    .catch((e) => {
      console.log("Error");
      return;
    });
  return data;
}

async function putSegmentInfoToDataBase(data) {
  const url = `${hostName}/api/v1/vamsvet/putSegmentInfoToDataBase`;
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };

  await fetch(url, options)
    .then((response) => {
      response.json().then((jsonResponse) => {
        console.log(jsonResponse);
        return jsonResponse;
      });
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
}

async function getSegmentInfoData() {
  const url = `${hostName}/api/v1/vamsvet/getSegmentInfoData`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  let data = [];
  await fetch(url, options)
    .then(async (response) => {
      await response
        .json()
        .then((jsonResponse) => {
          data = jsonResponse.data;
        })
        .catch((e) => {
          return;
        });
    })
    .catch((e) => {
      console.log("Error");
      return;
    });
  return data;
}

async function getSendedInfoData(
  rowNumber,
  article,
  brand,
  desission,
  priority
) {
  const url = `${hostName}/api/v1/vamsvet/getSendedInfoData?rowNumber=${rowNumber}&article=${article}&brand=${brand}&desission=${desission}&priority=${priority}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  let data = { data: [], total: 0 };
  await fetch(url, options)
    .then(async (response) => {
      await response
        .json()
        .then((jsonResponse) => {
          data = { data: jsonResponse.data, total: jsonResponse.total };
        })
        .catch((e) => {
          return;
        });
    })
    .catch((e) => {
      console.log("Error");
      return;
    });
  return data;
}

async function sendDataToVamsvetSetver() {
  const url = `${hostName}/api/v1/vamsvet/sendDataToVamsvetSetver`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  await fetch(url, options);
  return;
}

async function login(userinfo) {
  const url = `${hostName}/api/authorization/login`;
  const options = {
    method: "POST",
    body: JSON.stringify(userinfo), //{ username, password }
    headers: {
      "Content-Type": "application/json",
    },
  };
  let result;
  await fetch(url, options).then(async (response) => {
    result = await response.json();
  });
  return result;
}

async function checkAuth(token) {
  const url = `${hostName}/api/authorization/protected`;
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  let resp = await fetch(url, options);
  let result;
  if (resp.statusText === "OK") {
    result = true;
  } else {
    result = null;
  }
  return result;
}

async function getBrandList() {
  const url = `${hostName}/api/v1/vamsvet/getBrandList`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  let brandLs = [" - "];
  await fetch(url, options).then(async (brandList) => {
    await brandList.json().then(async (brandListJson) => {
      brandLs = await brandListJson.brandList;
    });
  });
  return brandLs;
}

async function getSegmentList(segment_name) {
  const url = `${hostName}/api/v1/vamsvet/getSegmentList?segment_name=${encodeURIComponent(segment_name)}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  let brandLs = [" - "];
  await fetch(url, options).then(async (brandList) => {
    await brandList.json().then(async (brandListJson) => {
      brandLs = await brandListJson;
    });
  });
  return brandLs;
}

async function updateSegment(segmentArray) {
  const url = `${hostName}/api/v1/vamsvet/updateSegment`;
  const options = {
    method: "POST",
    body: JSON.stringify(segmentArray),
    headers: {
      "Content-Type": "application/json",
    },
  };

  await fetch(url, options)
    .then((response) => {
      response.json().then((jsonResponse) => {
        console.log(jsonResponse);
        return jsonResponse;
      });
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
}

async function getAllInfoAboutSku(sku) {
  const url = `${hostName}/api/v1/vamsvet/getAllInfoAboutSku?sku=${sku}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  let info = {
    whatPrice: "Loading...",
    sellerInfo: {
      luftOfCP: "Loading...",
      numOfPositivConclusions: "Loading...",
      targetRashodMP: "Loading...",
      targetDolyaZakupa: "Loading...",
      operacionnieRashodiOnUnit: "Loading...",
      operacionnieRashodiPercent: "Loading...",
      nalog: "Loading...",
      normaIncom: "Loading...",
      choesenpPriority: "Loading...",
    },
    unitInfo: {
      unit: "Loading...",
    },
    decisions: {
      priceDecizion: "Loading...",
      rashodMPDecizion: "Loading...",
      dolyaSBSDecizion: "Loading...",
    },
    solvedValues: {
      fineshedPrice: "Loading...",
      priceFormula: "Loading...",
      finishedIncome: "Loading...",
      deltaRashodMP: "Loading...",
      deltaDolyaSebesa: "Loading...",
      priority: "Loading...",
      markup_to_mrc: "Loading...",
      finaleDesizion: "Loading...",
    },
  };
  await fetch(url, options).then(async (infoData) => {
    await infoData.json().then(async (infoDataJSON) => {
      info = await infoDataJSON;
    });
  });
  return info;
}

module.exports = {
  putDataToDataBase,
  getSellerPropertiesFromDataBase,
  putSegmentInfoToDataBase,
  getSegmentInfoData,
  getSendedInfoData,
  sendDataToVamsvetSetver,
  getBrandList,
  login,
  checkAuth,
  getSegmentList,
  updateSegment,
  getAllInfoAboutSku,
};
//export default { putDataToDataBase };
