import "../css/App.css";
import Header from "./Header";
import SellerInfoForm from "./SellerInfoForm";
import HomePage from "./HomePage";
import Segmentation from "./segmentPage/Segmentation";
import LastSendedData from "./showDataTable/LastSendedData";
import SegmentDBVisual from "./segmentPage/SegmentDBVisual";
import SegmentationUpdate from "./segmentPage/SegmentationUpdate";
import Bordpage from "./bordPage/Bordpage";
import { BrowserRouter, Routes, Route, Router } from "react-router-dom";
import Login from "./Login/Login";
import ProtectedRoute from "./Login/ProtectedRoute";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header title="Супер шапка" />
        <main>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="/sellerInfo" element={<ProtectedRoute />}>
              <Route path="/sellerInfo" element={<SellerInfoForm />} />
            </Route>
            <Route path="/segmentation" element={<ProtectedRoute />}>
              <Route path="/segmentation" element={<Segmentation />} />
            </Route>
            <Route path="/segmentation/show" element={<ProtectedRoute />}>
              <Route path="/segmentation/show" element={<SegmentDBVisual />} />
            </Route>
            <Route path="/segmentation/edit" element={<ProtectedRoute />}>
              <Route
                path="/segmentation/edit"
                element={<SegmentationUpdate />}
              />
            </Route>
            <Route path="/lastdownloads" element={<ProtectedRoute />}>
              <Route path="/lastdownloads" element={<LastSendedData />} />
            </Route>
            <Route path="/bordpage" element={<ProtectedRoute />}>
              <Route path="/bordpage" element={<Bordpage />} />
            </Route>
            <Route path="*" element={<ProtectedRoute />}>
              <Route path="*" element={<HomePage />} />
            </Route>
          </Routes>
        </main>
      </div>
    </BrowserRouter>
  );
}

export default App;
